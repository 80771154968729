import React from 'react';

import { InstantSearch, Configure, connectStateResults } from 'react-instantsearch-dom';
import { searchClient } from '@lib/algolia';
import { CustomSearchBox, StandardSearchHits } from '@components/search';

import Layout from '@components/layout';

const IndexResults = connectStateResults(({ searchState, searchResults, children }) =>
  searchState.query && searchResults && searchResults.nbHits !== 0 ? children : <></>
);

const Search = () => {
  return (
    <Layout>
      <div className="px-gutter pt-space-m">
        <div className="w-full max-w-[1280px] mx-auto">
          <InstantSearch searchClient={searchClient} indexName="pages">
            <div>
              <CustomSearchBox isLarge />
            </div>
            <StandardSearchHits />
            <Configure hitsPerPage={600} />
          </InstantSearch>
        </div>
      </div>
    </Layout>
  );
};

export default Search;
