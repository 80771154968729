import React from 'react';
import { Link } from 'gatsby';
import { connectHits } from 'react-instantsearch-dom';

import { useSanityPath } from '@hooks/useSanityPath';

const Hit = ({ title, seo, slug, _type }) => {
  const path = useSanityPath({ slug, _type });
  const { metaDesc } = seo || {};
  return (
    <Link to={path} className="py-10">
      <h4 className="text-xl md:blockH6 mb-half text-red">{title}</h4>
      <p className="mb-one">{metaDesc}</p>
      <span className="text-sm font-bold text-red lg:text-base">Read More</span>
    </Link>
  );
};

const Hits = ({ hits }) => (
  <div className="py-10">
    <div className="grid divide-y divide-[#9A9A9A]">
      {hits.map((hit) => (
        <Hit {...hit} />
      ))}
    </div>
  </div>
);

export const StandardSearchHits = connectHits(Hits);
