import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import cx from 'classnames';

import CancelIcon from '@svg/close.svg';
import SearchIcon from '@svg/search.svg';

const SearchBox = ({ currentRefinement, refine, placeholder, isLarge }) => (
  <form noValidate action="" role="search" className="relative">
    <input
      type="text"
      value={currentRefinement}
      onChange={(event) => refine(event.currentTarget.value)}
      className={cx('bg-white w-full px-4 py-4 rounded-lg placeholder-current border-none')}
      placeholder={placeholder || 'Search'}
    />
    {isLarge && (
      <div
        className={cx(
          'absolute top-1/2 transform -translate-y-1/2 right-4 transition-opacity duration-300 cursor-pointer',
          {
            'opacity-0': currentRefinement,
          }
        )}
      >
        <SearchIcon className="w-6 fill-current text-red" />
      </div>
    )}
    <div
      onClick={() => refine('')}
      className={cx(
        'absolute top-1/2 transform -translate-y-1/2 right-4 transition-opacity duration-300 cursor-pointer',
        {
          'opacity-0': !currentRefinement,
        }
      )}
    >
      <CancelIcon
        className={cx('fill-current text-red', {
          'w-4': !isLarge,
          'w-6': isLarge,
        })}
      />
    </div>
  </form>
);

export const CustomSearchBox = connectSearchBox(SearchBox);
